import React, {
  useEffect, useState, useContext, lazy, Suspense,
} from 'react';
import Layout from '../layout/Layout';
import { RecievingContext } from '../context/ReceivingProvider';
import { Utils } from '../context/UtilsContext';
import PurchaseOrderTable from '../components/PurchaseOrder/PurchaseOrderTable';
import searchArray from '../components/searchArray';
import SearchAndRecordFilter from '../components/Filter/SearchAndRecordFilter';
import Filter from '../components/Filter/Filter';

const PurchaseOrderItem = lazy(() => import('../components/PurchaseOrder/PurchaseOrderItem'));
const PurchaseOrderInvoice = lazy(() => import('../components/PurchaseOrder/PurchaseOrderInvoice'));

const PendingDeliveries = () => {
  const {
    receivingState,
    getPoList,
    getPoItems,
    getPoInvoice,
    addReceivedDetailsToInvoice,
  } = useContext(RecievingContext);
  const { setLoading } = useContext(Utils);
  // modal
  const [modalData, setModalData] = useState({ visible: false, data: {}, view: null });
  const closeModal = () => setModalData({ visible: false, data: {}, view: null });
  const openModal = (data, view) => setModalData({ visible: true, data, view });

  const { poList, supplierOption } = receivingState;
  // filter
  const defaultFilter = { supplier: null };
  const [filter, setFilter] = useState(defaultFilter);
  // search & records
  const [searchRecord, setSearchRecord] = useState({
    search: '',
    throttle: 500,
  });
  const filteredPoList = searchArray(searchRecord.search, poList, [
    'poNum',
    'supplier',
    'prNumbers',
  ]);
  const refreshContent = (throttle = searchRecord.throttle) => getPoList(setLoading, filter, throttle);
  const onFilter = () => getPoList(setLoading, filter, searchRecord.throttle);
  const onResetFilter = () => getPoList(setLoading, defaultFilter, searchRecord.throttle);
  useEffect(() => getPoList(setLoading, filter), []);
  return (
		<>
			<br />
			<h2>PURCHASE ORDER LIST</h2>
			<SearchAndRecordFilter
        filter={searchRecord}
        setFilter={setSearchRecord}
        refreshContent={refreshContent}
        exportRecordToCsv={false}
			/>

			<Filter
        supplierOption={supplierOption}
        filter={filter}
        setFilter={setFilter}
        onFilter={onFilter}
        onResetFilter={onResetFilter}
			/>

			<PurchaseOrderTable
        poList={filteredPoList}
        supplierOption={supplierOption}
        openModal={openModal}
			/>

			{modalData.visible && modalData.view === 'item' && (
			  <Suspense fallback={<div>Loading modal...</div>}>
			    <PurchaseOrderItem
            closeModal={closeModal}
            data={modalData.data}
            visible={modalData.visible}
            getPoItems={getPoItems}
			    />
			  </Suspense>
			)}

			{modalData.visible && modalData.view === 'invoice' && (
			  <Suspense fallback={<div>Loading modal...</div>}>
			    <PurchaseOrderInvoice
            closeModal={closeModal}
            data={modalData.data}
            visible={modalData.visible}
            getPoInvoice={getPoInvoice}
            addReceivedDetailsToInvoice={addReceivedDetailsToInvoice}
			    />
			  </Suspense>
			)}
		</>
  );
};

export default Layout(PendingDeliveries);
