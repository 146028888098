import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import Layout from '../layout/Layout';
import { RecievingContext } from '../context/ReceivingProvider';
import { Utils } from '../context/UtilsContext';
import ReceivingReportTable from '../components/ReceivingReport/ReceivingReportTable';
import searchArray from '../components/searchArray';
import SearchAndRecordFilter from '../components/Filter/SearchAndRecordFilter';
import Filter from '../components/Filter/Filter';

const ReceivingReport = () => {
  const {
    receivingState,
    getReceivingReport,
    deleteReceivingReport,
    printRrPDF,
    printRtvPDF,
  } = useContext(RecievingContext);
  const { setLoading } = useContext(Utils);
  const { rrList, supplierOption } = receivingState;
  // filter
  const defaultFilter = { supplier: null };
  const [filter, setFilter] = useState(defaultFilter);
  // search & records
  const [searchRecord, setSearchRecord] = useState({
    search: '',
    throttle: 500,
  });
  const filteredRRList = searchArray(searchRecord.search, rrList, [
    'poNum', 'supplier', 'rrNumber', 'materialSpecification', 'invoice', 'dr', 'remarks', 'date',
  ]);
  const refreshContent = (throttle = searchRecord.throttle) => (
    getReceivingReport(setLoading, filter, throttle)
  );
  const onFilter = () => getReceivingReport(setLoading, filter, searchRecord.throttle);
  const onResetFilter = () => {
    setFilter(defaultFilter);
    getReceivingReport(setLoading, defaultFilter, searchRecord.throttle);
  }
  useEffect(() => getReceivingReport(setLoading, filter), []);
  return (
    <>
      <br />
      <h2>RECEIVING REPORT LIST</h2>
      <SearchAndRecordFilter
        filter={searchRecord}
        setFilter={setSearchRecord}
        refreshContent={refreshContent}
      />

      <Filter
        supplierOption={supplierOption}
        filter={filter}
        setFilter={setFilter}
        onFilter={onFilter}
        onResetFilter={onResetFilter}
      />

      <ReceivingReportTable
        rrList={filteredRRList}
        supplierOption={supplierOption}
        deleteReceivingReport={deleteReceivingReport}
        setLoading={setLoading}
        printRrPDF={printRrPDF}
        printRtvPDF={printRtvPDF}
      />
    </>
  )
}

export default Layout(ReceivingReport)
